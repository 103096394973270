import React, {Component} from 'react';
import styled from 'styled-components';
import {Breadcrumb, BreadcrumbItem, Button} from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';
import kaxerPhone from "../assets/img/KaxerPhone.PNG";
import kaxerGif from "../assets/img/kaxer.gif";
import proGif from "../assets/img/professionals.gif";
import rect from "../assets/img/OrangeRect.png";
import header from "../assets/img/OrangeHeader.png";
import logo from '../assets/img/KAPPERONLY.png';
import kaxerExample from '../assets/img/kaxerExample2.png';
import kaxer from '../assets/img/kaxer.PNG';
import preview from '../assets/img/kaxerPreview.PNG';

class Kaxer extends Component {
    render() {
        return (
            <Route path="/kaxer">
                <div style={rectStyle}>
                    <label>
                    </label>
                </div>
                <div style={navbarStyle}>
                    <a href={'/'}>Home </a>
                    <label> | Kaxer</label>
                </div>

                <Container>
                    <Row>
                        <Col sm="12" md={{size: 6, offset: 3}}>
                            <img style={imgKaxerStyle} src={logo} alt="logo"/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='kaxerPhone' src={kaxerExample}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <div>
                                <label style={sharingTextStyle}>
                                    <b>K</b>axer
                                </label>
                            </div>
                            <div>
                                <label style={thesisSubTextStyle}>
                                    (<b>M</b>aster's <b>T</b>hesis • <b>J</b>uly <b>2</b>0<b>2</b>0)
                                </label>
                            </div>

                            <div>
                                <Button style={projectActivity}>
                                    Mobile App Development
                                </Button>
                                <Button style={projectActivity}>
                                    UX & UI Design
                                </Button>
                                <Button style={projectActivity}>
                                    Full-stack Web Development
                                </Button>
                                <Button style={projectActivity}>
                                    Cloud Computing
                                </Button>
                            </div>

                            <div>
                                <label style={questionLabelStyle}>
                                    Kaxer is an mobile application that allows customers to book beauty appointments
                                    directly to their home.
                                </label>
                            </div>

                            <div>
                                <label style={websiteTextStyle}>
                                    <b>W</b>ebsite
                                </label>
                                <label>
                                    <a href={'https://kaxer.app'}>
                                        www.kaxer.app
                                    </a>
                                </label>
                            </div>

                            <div>
                                <label style={websiteTextStyle}>
                                    <b>L</b>aunch
                                </label>
                                <label>
                                    September 2020
                                </label>
                            </div>

                            <div>
                                <label style={websiteTextStyle}>
                                    <b>T</b>echnology
                                </label>
                                <label>
                                    JavaScript • TypeScript • HTML • CSS • NoSQL • Firebase
                                </label>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="1" md="1">
                        <Col style={imgContainer}>
                            <img style={imgPreviewStyle} alt='kaxerGif' src={preview}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='kaxerGif' src={kaxerGif}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <label style={demoTextStyle}>
                                <b>C</b>ustomer <b>d</b>emo.
                            </label>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='kaxerProGif' src={proGif}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <label style={demoTextStyle}>
                                <b>P</b>rofessional <b>d</b>emo.
                            </label>
                        </Col>
                    </Row>
                </Container>

            </Route>
        );
    }
}

const rectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${rect})`,
    fontSize: '20px'
};

const navbarStyle = {
    color: 'white',
    backgroundImage: `url(${header})`,
    width: '100vw',
    height: '100%',
};

const imgKaxerStyle = {
    width: '300px',
    height: '70px',
    marginLeft: '22%',
    marginTop: '5%',
    marginBottom: '5%',
};

const labelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
    borderRadius: '5px',
};

const specialLabelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
    borderRadius: '5px', opacity: '0.9',
    backgroundColor: '#f6f6f6',
};

const projectActivity = {
    margin: '10px 10px 0 0',
    padding: '4px 10px',
    borderRadius: '20px',
    border: '1px solid #dcdcdc',
    fontWeight: '600',
    marginLeft: '1px',
    backgroundColor: '#e8e8e8',
    color: '#4c494b',
};

const implementationLabelStyle = {
    color: '#575757',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
};

const aimLabelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
    borderRadius: '5px', opacity: '0.9',
    backgroundColor: '#f6f6f6',
};

const questionLabelStyle = {
    color: '#828282',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#f6f6f6',
    marginTop: '3%',
};

const containerStyle = {
    backgroundColor: '#e8e8e8',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid #e8e8e8 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgContainer = {
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid white 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgStyle = {
    width: '50%',
    height: '90%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '1%',
};

const imgPreviewStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const searchImgStyle = {
    width: '40%',
    height: '40%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '1%',
};

const sharingTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
};

const demoTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '35px',
    marginTop: '50%',
};

const websiteTextStyle = {
    color: '#605d5f',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
    marginRight: '4%',
};

const thesisSubTextStyle = {
    color: '#777777',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
};

const sharingContainer = {
    justifyContent: "center",
    alignItems: "center",
    opacity: '0.9',
    backgroundColor: '#eac296',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid #eac296 2px',
    fontStyle: 'iCiel Gotham Thin',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
    marginBottom: '1%',
};

const explanationContainerStyle = {
    textAlign: 'center',
    marginTop: '1%',
    marginBottom: '1%',
};

export default Kaxer;
