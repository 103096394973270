import React, { Component } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Home from "./components/Home";
import {
    Switch,
    Route,
} from "react-router-dom";
import Contact from "./components/Contact";
import Kaxer from "./components/Kaxer";
import SchoolPlano from "./components/SchoolPlano";
import FaceRec from "./components/FaceRec";
import Pamosy from "./components/Pamosy";
import InterMedi from "./components/InterMedi";
import MedicationCare from "./components/MedicationCare";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">

                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route path="/contact">
                            <Contact/>
                        </Route>
                        <Route path="/kaxer">
                            <Kaxer/>
                        </Route>
                        <Route path="/schoolplano">
                            <SchoolPlano/>
                        </Route>
                        <Route path="/facerec">
                            <FaceRec/>
                        </Route>
                        <Route path="/pamosy">
                            <Pamosy/>
                        </Route>
                        <Route path="/intermedi">
                            <InterMedi/>
                        </Route>
                        <Route path="/medicationcare">
                            <MedicationCare/>
                        </Route>

                    </Switch>

                </div>

            </Router>
        );
    }
}

export default App;
