import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';
import linkedIn from "../assets/img/LI-Logo.png";
import rect from "../assets/img/OrangeRect.png";
import header from "../assets/img/OrangeHeader.png";
import intro from "../assets/img/intro.gif";

class Contact extends Component {

    render() {
        return (
            <Route path="/contact">
                <div style={rectStyle}>
                    <label>

                    </label>
                </div>
                <div style={navbarStyle}>
                    <a href={'/'}>Home </a>
                    <label> | Contact</label>
                </div>

                <Container>
                    <Row>
                        <Col sm="12" md={{size: 6, offset: 3}}>
                            <div href="/">
                                <img alt="intro" style={introStyle} src={intro}/>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col sm="12" md={{size: 12, offset: 3}}>
                            <label style={projectStyle}><b>G</b>et <b>i</b>n <b>t</b>ouch <b>w</b>ith <b>m</b>e</label>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col>
                            <label style={emailLabelStyle}>
                                <a href={"mailto:florin.schwall@gmail.com"}>Email</a>
                            </label>
                        </Col>
                        <Col>
                            <a href={'https://www.linkedin.com/in/florin-schwall-998a11a2'}>
                                <img style={linkedInImgStyle} alt='logo' src={linkedIn}/>
                            </a>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col sm="12" md={{size: 12, offset: 3}}>
                            <label style={projectStyle}><b>I</b> am <b>l</b>ooking <b>f</b>or <b>o</b>pportunities <b>f</b>rom <b>J</b>uly <b>2</b>0<b>2</b>0.</label>
                        </Col>
                    </Row>
                </Container>
            </Route>
        );
    }
}

const rectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${rect})`,
    fontSize: '20px'
};

const navbarStyle = {
    color: 'white',
    backgroundImage: `url(${header})`,
    width: '100vw',
    height: '100%',
};

const projectStyle = {
    fontSize: '35px',
    textAlign: 'center',
    marginLeft: '2%',
    marginBottom: '5%',
    marginTop: '10%',
    color: '#4c494b',
};

const introStyle = {
    width: '100%',
    height: '30px',
    marginBottom: '1em',
    marginTop: '9em',
};

const contactLabelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
};

const containerStyle = {
    backgroundColor: '#e8e8e8',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid #e8e8e8 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const availableContainerStyle = {
    backgroundColor: '#e8e8e8',
    textAlign: 'center',
    marginTop: '2%',
    borderRadius: '10px',
    border: 'solid #e8e8e8 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const linkedInImgStyle = {
    width: '70%',
    height: '80%',
    marginLeft: '15%',
    marginRight: '15%',
    textAlign: 'center',
    marginTop: '4%',
    borderRadius: '5px',
    marginBottom: '1%',
};

const emailLabelStyle = {
    width: '70%',
    height: '80%',
    marginLeft: '15%',
    marginRight: '15%',
    textAlign: 'center',
    marginTop: '6%',
    borderRadius: '5px',
    marginBottom: '1%',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '35px',
};

export default Contact;
