import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem, Button} from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';
import logo from "../assets/img/schoolPlanoSmall.PNG";
import demo from "../assets/img/schoolPlano.gif";
import rect from "../assets/img/OrangeRect.png";
import header from "../assets/img/OrangeHeader.png";
import schoolPlanoExample from "../assets/img/schoolPlanoPhoneWhite.png";

class SchoolPlano extends Component {
    render() {
        return (
            <Route path="/schoolplano">
                <div style={rectStyle}>
                    <label>
                    </label>
                </div>
                <div style={navbarStyle}>
                    <a href={'/'}>Home </a>
                    <label> | SchoolPlano</label>
                </div>

                <Container>
                    <Row>
                        <Col sm="12" md={{size: 6, offset: 3}}>
                            <img style={imgSchoolPlanoStyle} src={logo} alt="logo"/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='kaxerPhone' src={schoolPlanoExample}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <div>
                                <label style={sharingTextStyle}>
                                    <b>S</b>chool<b>P</b>lano
                                </label>
                            </div>
                            <div>
                                <label style={thesisSubTextStyle}>
                                    (<b>P</b>ersonal <b>U</b>niversity <b>P</b>roject • <b>J</b>uly <b>2</b>0<b>1</b>9)
                                </label>
                            </div>

                            <div>
                                <Button style={projectActivity}>
                                    Software Development
                                </Button>
                                <Button style={projectActivity}>
                                    Full-stack Web Development
                                </Button>
                            </div>

                            <div>
                                <label style={questionLabelStyle}>
                                    SchoolPlano is an application that allows teachers and children to create their individual timetable at school.
                                </label>
                            </div>

                            <div>
                                <label style={websiteTextStyle}>
                                    <b>T</b>echnology
                                </label>
                                <label style={technologytyle}>
                                    ReactJS • API • AJAX • UI Design • HTML • CSS
                                </label>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgDemoContainer}>
                            <img style={demoImgStyle} alt='demoImg' src={demo}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <label style={demoTextStyle}>
                                <b>D</b>emo.
                            </label>
                        </Col>
                    </Row>
                </Container>

            </Route>
        );
    }
}

const thesisSubTextStyle = {
    color: '#d8d8d8',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
};

const imgSchoolPlanoStyle = {
    width: '60%',
    height: '100%',
    marginLeft: '17%',
    marginBottom: '1%',
};

const websiteTextStyle = {
    color: '#d8d8d8',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
    marginRight: '4%',
};

const projectActivity = {
    margin: '10px 10px 0 0',
    padding: '4px 10px',
    borderRadius: '20px',
    border: '1px solid #dcdcdc',
    fontWeight: '600',
    marginLeft: '1px',
    backgroundColor: '#e8e8e8',
    color: '#4c494b',
};

const technologytyle = {
    color: 'white',
};

const questionLabelStyle = {
    color: '#828282',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#f6f6f6',
    marginTop: '3%',
};

const explanationContainerStyle = {
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '1%',
};

const imgContainer = {
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid white 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgDemoContainer = {
    backgroundColor: 'black',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid black 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const rectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${rect})`,
    fontSize: '20px'
};

const navbarStyle = {
    color: 'white',
    backgroundImage: `url(${header})`,
    width: '100vw',
    height: '100%',
};

const imgStyle = {
    width: '50%',
    height: '90%',
    marginLeft: '15%',
    marginRight: '15%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '1%',
};

const logoImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const demoImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
};

const sharingTextStyle = {
    color: 'white',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '5%',
};

const demoTextStyle = {
    color: 'white',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '25%',
};

const sharingContainer = {
    justifyContent: "center",
    alignItems: "center",
    opacity: '0.9',
    backgroundColor: '#5a7e18',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid #5a7e18 2px',
    fontStyle: 'iCiel Gotham Thin',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
    marginBottom: '1%',
};

const labelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
};

const implementationLabelStyle = {
    color: '#575757',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
};

const containerStyle = {
    backgroundColor: '#e8e8e8',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid #e8e8e8 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

export default SchoolPlano;
