import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem, Button} from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';
import medication from "../assets/img/medication.jpg";
import network  from "../assets/img/network.PNG";
import filter from "../assets/img/filter.gif";
import rect from "../assets/img/OrangeRect.png";
import header from "../assets/img/OrangeHeader.png";
import logo from "../assets/img/MedicationCareSmall.PNG";
import medicareExample from "../assets/img/medicationcareWhite.png";
import hacker from "../assets/img/hacker.jpg";

class MedicationCare extends Component {
    render() {
        return (
            <Route path="/medicationcare">
                <div style={rectStyle}>
                    <label>
                    </label>
                </div>
                <div style={navbarStyle}>
                    <a href={'/'}>Home </a>
                    <label> | MedicationCare</label>
                </div>

                <Container>
                    <Row>
                        <Col sm="12" md={{size: 6, offset: 3}}>
                            <img style={imgKaxerStyle} src={logo} alt="logo"/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='kaxerPhone' src={medicareExample}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <div>
                                <label style={sharingTextStyle}>
                                    <b>M</b>edication<b>C</b>are
                                </label>
                            </div>
                            <div>
                                <label style={thesisSubTextStyle}>
                                    (<b>U</b>niversity <b>G</b>roup <b>P</b>roject • <b>J</b>anuary <b>2</b>0<b>1</b>8)
                                </label>
                            </div>

                            <div>
                                <Button style={projectActivity}>
                                    Application Development
                                </Button>
                                <Button style={projectActivity}>
                                    Security
                                </Button>
                                <Button style={projectActivity}>
                                    Full-stack Web Development
                                </Button>
                            </div>

                            <div>
                                <label style={questionLabelStyle}>
                                    MedicationCare is an application that supports physicians and gives a warning if a side-effect is dangerous.
                                </label>
                            </div>

                            <div>
                                <label style={websiteTextStyle}>
                                    <b>T</b>echnology
                                </label>
                                <label>
                                    Vaadin • Java • JavaEE • JavaScript • Apache Shiro  • TomEE • PostgreSQL
                                </label>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={medicationContainer}>
                            <label style={medicationTextStyle}>
                                <b>D</b>isadvantages <b>o</b>f <b>d</b>rugs <b>a</b>re <b>t</b>he <b>s</b>ide-<b>e</b>ffects.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='medicationImg' src={medication}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={medicationContainer}>
                            <label style={medicationTextStyle}>
                                <b>A</b>nd <b>t</b>here <b>a</b>re <b>s</b>o <b>m</b>any.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='networkImg' src={network}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={medicationContainer}>
                            <label style={medicationTextStyle}>
                                <b>M</b>edication<b>C</b>are <b>r</b>ecognises <b>d</b>angerous <b>s</b>ide-effects.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='filterImg' src={filter}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={medicationContainer}>
                            <label style={medicationTextStyle}>
                                <b>R</b>equirement: <b>M</b>edication<b>C</b>are <b>h</b>as <b>t</b>o <b>b</b>e <b>s</b>ecure <b>a</b>gainst:
                                <br/>
                                • <b>S</b>poofing
                                <br/>
                                • <b>T</b>ampering
                                <br/>
                                • <b>R</b>epudiation
                                <br/>
                                • <b>I</b>nformation Disclosure
                                <br/>
                                • <b>D</b>enial of Service
                                <br/>
                                • <b>E</b>levation of Privilege
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='filterImg' src={hacker}/>
                        </Col>
                    </Row>
                </Container>
            </Route>
        );
    }
}

const rectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${rect})`,
    fontSize: '20px'
};

const sharingContainer = {
    justifyContent: "center",
    alignItems: "center",
    opacity: '0.9',
    backgroundColor: '#eac296',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid #eac296 2px',
    fontStyle: 'iCiel Gotham Thin',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
    marginBottom: '1%',
};

const sharingTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
};

const thesisSubTextStyle = {
    color: '#777777',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
};

const projectActivity = {
    margin: '10px 10px 0 0',
    padding: '4px 10px',
    borderRadius: '20px',
    border: '1px solid #dcdcdc',
    fontWeight: '600',
    marginLeft: '1px',
    backgroundColor: '#e8e8e8',
    color: '#4c494b',
};

const questionLabelStyle = {
    color: '#828282',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#f6f6f6',
    marginTop: '3%',
};

const websiteTextStyle = {
    color: '#605d5f',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
    marginRight: '4%',
};

const navbarStyle = {
    color: 'white',
    backgroundImage: `url(${header})`,
    width: '100vw',
    height: '100%',
};

const explanationContainerStyle = {
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '1%',
};

const labelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
};

const moreLabelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#f6f6f6',
    marginBottom: '1%'
};

const implementationLabelStyle = {
    color: '#575757',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
};

const containerStyle = {
    backgroundColor: '#e8e8e8',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid #e8e8e8 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgKaxerStyle = {
    width: '300px',
    height: '60px',
    marginLeft: '22%',
    marginTop: '5%',
    marginBottom: '5%',
};

const imgContainer = {
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid white 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgStyle = {
    width: '50%',
    height: '90%',
    marginLeft: '15%',
    marginRight: '15%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '1%',
};

const medicationTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '10%',
};

const medicationContainer = {
    justifyContent: "center",
    alignItems: "center",
    opacity: '0.9',
    backgroundColor: '#eac296',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid #eac296 2px',
    fontStyle: 'iCiel Gotham Thin',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
    marginBottom: '1%',
};

export default MedicationCare;
