import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem, Button} from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';
import intermedi from "../assets/img/IntermediLogin.PNG";
import hospital from "../assets/img/Hospital.PNG";
import problem from "../assets/img/ProblemInterMedi.PNG";
import walk from "../assets/img/walk.PNG";
import login from "../assets/img/InterMediLoginCustomer.PNG";
import ring from "../assets/img/InterMediRing.PNG";
import dashboard from "../assets/img/InterMediDashboard.PNG";
import rect from "../assets/img/OrangeRect.png";
import header from "../assets/img/OrangeHeader.png";
import logo from "../assets/img/interMediLogo.PNG";
import interMediExample from "../assets/img/intermediWhite.png";

class InterMedi extends Component {
    render() {
        return (
            <Route path="/intermedi">
                <div style={rectStyle}>
                    <label>
                    </label>
                </div>
                <div style={navbarStyle}>
                    <a href={'/'}>Home </a>
                    <label> | InterMedi</label>
                </div>

                <Container>
                    <Row>
                        <Col sm="12" md={{size: 6, offset: 3}}>
                            <img style={logoImgStyle} alt='img' src={logo}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='img' src={interMediExample}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <div>
                                <label style={sharingTextStyle}>
                                    <b>I</b>nter<b>M</b>edi
                                </label>
                            </div>
                            <div>
                                <label style={thesisSubTextStyle}>
                                    (<b>B</b>achelor's <b>T</b>hesis • <b>A</b>ugust <b>2</b>0<b>1</b>7)
                                </label>
                            </div>

                            <div>
                                <Button style={projectActivity}>
                                    Mobile App Development
                                </Button>
                                <Button style={projectActivity}>
                                    UI Design
                                </Button>
                                <Button style={projectActivity}>
                                    Full-stack Web Development
                                </Button>
                            </div>

                            <div>
                                <label style={questionLabelStyle}>
                                    InterMedi supports nurses in their workflow.
                                </label>
                            </div>

                            <div>
                                <label style={websiteTextStyle}>
                                    <b>T</b>echnology
                                </label>
                                <label>
                                    JavaScript • TypeScript • HTML • CSS • PostgreSQL • Maven
                                </label>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={sharingContainer}>
                            <label style={workflowTextStyle}>
                                <b>T</b>he <b>w</b>orkflow <b>i</b>n <b>a</b> <b>h</b>ospital <b>h</b>as <b>i</b>ts <b>p</b>itfalls.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={hospitalImgStyle} alt='hospitalIMG' src={hospital}/>
                        </Col>
                    </Row>
                </Container>



                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='interMediIMG' src={intermedi}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <label style={aimTextStyle}>
                                <b>I</b>nter<b>M</b>edi <b>a</b>ims <b>t</b>o <b>i</b>mprove <b>t</b>he <b>w</b>orkflow <b>o</b>f <b>n</b>urses.
                            </label>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={sharingContainer}>
                            <label style={ringTextStyle}>
                                <b>P</b>atients <b>c</b>an <b>r</b>ing <b>w</b>ith <b>a</b> <b>r</b>eason <b>a</b>nd <b>s</b>ave <b>v</b>aluable <b>t</b>ime.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={phoneImgStyle} alt='interMediIMG' src={ring}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={phone2ImgStyle} alt='interMediIMG' src={dashboard}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <label style={integrateTextStyle}>
                                <b>N</b>urses <b>c</b>an <b>v</b>iew <b>w</b>hat <b>t</b>he <b>p</b>atients <b>r</b>equire <b>a</b>nd <b>i</b>ntegrated <b>i</b>t <b>i</b>nto <b>t</b>heir <b>w</b>orkflow.
                            </label>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={sharingContainer}>
                            <label style={sharingTextStyle}>
                                <b>U</b>se <b>C</b>ase: <b>P</b>erson <b>X</b> <b>n</b>eeds <b>a</b> <b>g</b>lass <b>o</b>f <b>w</b>ater <b>a</b>nd <b>i</b>s <b>r</b>inging <b>f</b>or <b>a</b> <b>n</b>urse.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={problemImgStyle} alt='problem' src={problem}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={walkImgStyle} alt='walkIMG' src={walk}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <label style={sharingTextStyle}>
                                <b>H</b>ow <b>o</b>ften <b>w</b>alks <b>a</b> <b>n</b>urse <b>b</b>ack <b>a</b>nd <b>f</b>orth <b>t</b>o <b>a</b> <b>p</b>atient? <b>T</b>he <b>a</b>nswer <b>i</b>s <b>t</b>oo <b>o</b>ften.
                            </label>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={sharingContainer}>
                            <label style={aimTextStyle}>
                                <b>S</b>ave <b>v</b>aluable <b>t</b>ime <b>w</b>ith <b>I</b>nter<b>M</b>edi.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={walkImgStyle} alt='walkIMG' src={login}/>
                        </Col>
                    </Row>
                </Container>

            </Route>
        );
    }
}

const rectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${rect})`,
    fontSize: '20px'
};

const navbarStyle = {
    color: 'white',
    backgroundImage: `url(${header})`,
    width: '100vw',
    height: '100%',
};

const labelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
};

const implementationLabelStyle = {
    color: '#575757',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
};

const thesisSubTextStyle = {
    color: '#777777',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
};

const projectActivity = {
    margin: '10px 10px 0 0',
    padding: '4px 10px',
    borderRadius: '20px',
    border: '1px solid #dcdcdc',
    fontWeight: '600',
    marginLeft: '1px',
    backgroundColor: '#e8e8e8',
    color: '#4c494b',
};

const questionLabelStyle = {
    color: '#828282',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#f6f6f6',
    marginTop: '3%',
};

const websiteTextStyle = {
    color: '#605d5f',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
    marginRight: '4%',
};

const useCaseLabelStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#eac296',
    marginBottom: '1%'
};

const containerStyle = {
    backgroundColor: '#e8e8e8',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid #e8e8e8 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgContainer = {
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid white 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const phoneImgStyle = {
    width: '40%',
    height: '85%',
    marginLeft: '10%',
    marginRight: '10%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '15%',
};

const phone2ImgStyle = {
    width: '40%',
    height: '80%',
    marginLeft: '10%',
    marginRight: '10%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '15%',
};


const hospitalImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const logoImgStyle = {
    width: '100%',
    height: '90%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '5%',
    marginBottom: '5%',
};

const walkImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const demoImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '15%',
};

const problemImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const sharingTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
};

const workflowTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '5%',
};

const aimTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '50%',
};

const ringTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '25%',
};

const integrateTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '18%',
};

const sharingContainer = {
    justifyContent: "center",
    alignItems: "center",
    opacity: '0.9',
    backgroundColor: '#eac296',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid #eac296 2px',
    fontStyle: 'iCiel Gotham Thin',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
    marginBottom: '1%',
};

const explanationContainerStyle = {
    textAlign: 'center',
    marginTop: '1%',
    marginBottom: '1%',
};

export default InterMedi;
