import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem, Button} from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';
import monitor from "../assets/img/hospitalMonitor.jpg";
import idea from "../assets/img/PamosyIdea.PNG";
import pamosyMonitor from "../assets/img/pamosyMonitor.PNG";
import doctor from "../assets/img/doctor.jpg";
import connection from "../assets/img/connection.PNG";
import rect from "../assets/img/OrangeRect.png";
import header from "../assets/img/OrangeHeader.png";
import logo from "../assets/img/pamosySmall.PNG";
import pamosyExample from "../assets/img/pamosyWhite.png";

class Pamosy extends Component {
    render() {
        return (
            <Route path="/pamosy">
                <div style={rectStyle}>
                    <label>
                    </label>
                </div>
                <div style={navbarStyle}>
                    <a href={'/'}>Home </a>
                    <label> | Pamosy</label>
                </div>

                <Container>
                    <Row>
                        <Col style={imgLogoContainer} sm="12" md={{size: 6, offset: 3}}>
                                <img style={imgStyle} alt='img' src={logo}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={imgContainer}>
                            <img style={imgStyle} alt='kaxerPhone' src={pamosyExample}/>
                        </Col>
                        <Col style={sharingContainer}>
                            <div>
                                <label style={sharingTextStyle}>
                                    <b>P</b>amosy
                                </label>
                            </div>
                            <div>
                                <label style={thesisSubTextStyle}>
                                    (<b>U</b>niversity <b>G</b>roup <b>P</b>roject • <b>J</b>une <b>2</b>0<b>1</b>8)
                                </label>
                            </div>

                            <div>
                                <Button style={projectActivity}>
                                    Software Development
                                </Button>
                                <Button style={projectActivity}>
                                    Hardware Usage
                                </Button>
                                <Button style={projectActivity}>
                                    Real-time Monitoring
                                </Button>
                                <Button style={projectActivity}>
                                    Database
                                </Button>
                            </div>

                            <div>
                                <label style={questionLabelStyle}>
                                    Pamosy is a mobile application for Android that tracks real-time vital signs of patients with fitness tracker.
                                </label>
                            </div>

                            <div>
                                <label style={websiteTextStyle}>
                                    <b>T</b>echnology
                                </label>
                                <label>
                                    Java • PostgreSQL • Linux • Apache • API • Bluetooth Low Energy • Raspberry Pi
                                </label>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={pamosyContainer}>
                            <label style={pamosyTextStyle}>
                                <b>P</b>amosy's <b>i</b>dea <b>i</b>s <b>t</b>o <b>b</b>ring <b>r</b>eal-<b>t</b>ime <b>m</b>onitoring <b>t</b>o <b>y</b>ou.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={ideaImgStyle} alt='ideaImg' src={idea}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={pamosyContainer}>
                            <label style={pamosyTextStyle}>
                                <b>P</b>amosy <b>a</b>llows <b>t</b>he <b>m</b>onitoring <b>o</b>f <b>r</b>eal-<b>t</b>ime <b>v</b>ital <b>s</b>igns <b>2</b>4/<b>7</b>.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={loginImgStyle} alt='loginImg' src={pamosyMonitor}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={pamosyContainer}>
                            <label style={pamosyTextStyle}>
                                <b>A</b>ll <b>w</b>hat <b>y</b>ou <b>n</b>eed <b>i</b>s <b>a</b>n <b>I</b>nternet <b>c</b>onnection.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={connectionImgStyle} alt='connectionImg' src={connection}/>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row xs="1" sm="2" md="2">
                        <Col style={pamosyContainer}>
                            <label style={pamosyTextStyle}>
                                <b>A</b>nd <b>s</b>end <b>t</b>he <b>r</b>eal-<b>t</b>ime <b>d</b>ata <b>t</b>o <b>y</b>our <b>d</b>octor.
                            </label>
                        </Col>
                        <Col style={imgContainer}>
                            <img style={loginImgStyle} alt='doctorImg' src={doctor}/>
                        </Col>
                    </Row>
                </Container>

            </Route>
        );
    }
}

const rectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${rect})`,
    fontSize: '20px'
};

const navbarStyle = {
    color: 'white',
    backgroundImage: `url(${header})`,
    width: '100vw',
    height: '100%',
};

const imgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const sharingContainer = {
    justifyContent: "center",
    alignItems: "center",
    opacity: '0.9',
    backgroundColor: '#eac296',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid #eac296 2px',
    fontStyle: 'iCiel Gotham Thin',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
    marginBottom: '1%',
};

const sharingTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
};

const thesisSubTextStyle = {
    color: '#777777',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
};

const projectActivity = {
    margin: '10px 10px 0 0',
    padding: '4px 10px',
    borderRadius: '20px',
    border: '1px solid #dcdcdc',
    fontWeight: '600',
    marginLeft: '1px',
    backgroundColor: '#e8e8e8',
    color: '#4c494b',
};

const questionLabelStyle = {
    color: '#828282',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#f6f6f6',
    marginTop: '3%',
};

const websiteTextStyle = {
    color: '#605d5f',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '20px',
    marginRight: '4%',
};

const explanationContainerStyle = {
    textAlign: 'center',
    marginTop: '4%',
    marginBottom: '1%',
};

const labelStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
};

const implementationLabelStyle = {
    color: '#575757',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '20px',
};

const containerStyle = {
    backgroundColor: '#e8e8e8',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid #e8e8e8 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const butContainerStyle = {
    color: '#939393',
    fontStyle: 'iCiel Gotham Thin',
    fontSize: '30px',
    borderRadius: '5px',
    opacity: '0.9',
    backgroundColor: '#f6f6f6',
    marginBottom: '1%'
};

const imgContainer = {
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: '10px',
    border: 'solid white 2px',
    marginBottom: '1%',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
};

const imgLogoContainer = {
    textAlign: 'center',
    borderRadius: '10px',
    marginBottom: '5%',
    marginTop: '5%',
};

const monitorImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
    marginBottom: '1%',
    opacity: '1',
};

const ideaImgStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const loginImgStyle = {
    width: '50%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const connectionImgStyle = {
    width: '50%',
    height: '80%',
    marginTop: '7%',
    backgroundColor: 'white',
    textAlign: 'center',
};

const pamosyContainer = {
    justifyContent: "center",
    alignItems: "center",
    opacity: '0.9',
    backgroundColor: '#eac296',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid #eac296 2px',
    fontStyle: 'iCiel Gotham Thin',
    boxShadow: '0 6px 16px 0 rgba(0,0,0,0.05), 0 3px 6px 0 rgba(0,0,0,0.05)',
    marginBottom: '1%',
};

const pamosyTextStyle = {
    color: '#4c494b',
    fontStyle: 'iCiel Gotham Medium',
    fontSize: '30px',
    marginTop: '3%',
};

export default Pamosy;
