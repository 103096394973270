import React, {Component, useState} from 'react';
import styled from 'styled-components';
import {Form, FormGroup, Input} from 'reactstrap';
import header from '../assets/img/OrangeHeader.png';
import rect from '../assets/img/OrangeRect.png';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Card, Button, CardImg, CardTitle, CardText, CardDeck,
    CardSubtitle, CardBody,
} from 'reactstrap';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {Container, Row, Col} from 'reactstrap';

import spotBrown from '../assets/img/spot.png';
import spotGreen from '../assets/img/spotGreen.png';
import phone from '../assets/img/phone.png';
import kaxerExample from '../assets/img/kaxerExample.PNG';
import pamosy from '../assets/img/pamosy.PNG';
import facerec from '../assets/img/facerec.PNG';
import schoolPlano from '../assets/img/schoolPlano.PNG';
import medicationCare from '../assets/img/medicationcare.PNG';
import intermedi from '../assets/img/intermedi.PNG';

import greenHeader from '../assets/img/GreenHeader.png';
import greenBottom from '../assets/img/GreenBottom.png';
import greenRect from '../assets/img/GreenRect.png';

import redHeader from '../assets/img/RedHeader.png';
import redRect from '../assets/img/RedRect.png';
import redBottom from '../assets/img/RedBottom.png';
import spotRed from '../assets/img/spotRed.png';
import spotBlue from '../assets/img/spotBlue.png'

import blueHeader from '../assets/img/BlueHeader.png';
import blueRect from '../assets/img/BlueRect.png';
import blueBottom from '../assets/img/BlueBottom.png';

const Home = (props) => {

    const state = {checked: true};

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (

        <Router exact path="/">
            <div style={rectStyle}>
                <label>.</label>
            </div>
                <Navbar style={navbarStyle} light expand="md">

                    <NavbarToggler onClick={toggle} style={menuStyle}/>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav  className="mr-auto" navbar>
                            <NavItem>
                                <NavLink href="/" style={homeStyle}><b>H</b>ome</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/contact" style={fontStyle}><b>C</b>ontact</NavLink>
                            </NavItem>

                        </Nav>
                    </Collapse>
                </Navbar>


            <div>
                <label style={nameStyle}><b>F</b>lorin <b>S</b>chwall</label>
            </div>

            <Container>
                <Row>
                    <Col sm="12" md={{size: 6, offset: 3}}>
                        <label style={projectStyle}><b>P</b>rojects</label>
                    </Col>
                </Row>
            </Container>

            <Container style={gapStyle}>
                <Row xs="1" sm="2" md="2" lg="2">
                    <Col>
                        <div>
                            <label style={joinText}>
                                <b>J</b>oin <b>t</b>he <b>f</b>uture <b>w</b>ith <b>K</b>axer.
                            </label>
                        </div>

                        <div>
                            <label>
                                Book hair stylists directly to your home.
                            </label>
                        </div>

                        <div style={projectName}>
                            Master's Thesis (July 2020)
                        </div>

                        <div >
                            <Button style={projectActivity}>
                                Mobile App Development
                            </Button>
                            <Button style={projectActivity}>
                                UX & UI Design
                            </Button>
                            <Button style={projectActivity}>
                                Full-stack Web Development
                            </Button>
                            <Button style={projectActivity}>
                                Cloud Computing
                            </Button>
                        </div>

                        <div>
                            <a href={'/kaxer'}>
                                <Button style={kaxerButton}>
                                    <b>V</b>iew more
                                </Button>
                            </a>
                        </div>
                    </Col>
                    <Col>
                        <div style={brownSpotStyle}>
                            <a href={'/kaxer'}>
                            <img style={phoneStyle} alt="photo" src={kaxerExample}/>
                            </a>
                        </div>

                    </Col>
                </Row>
            </Container>


            <img style={greenHeaderStyle} alt="photo" src={greenHeader}/>
            <div style={greenRectStyle}>

                <Container>
                    <Row xs="1" sm="2" md="2" lg="2">
                        <Col>
                            <div style={spotGreenStyle}>
                                <a href={'/schoolplano'}>
                                <img style={phoneLeftStyle} alt="photo" src={schoolPlano}/>
                                </a>
                            </div>
                        </Col>
                        <Col>

                            <div>
                                <label style={planText}>
                                    <b>P</b>lan <b>y</b>our <b>d</b>ay <b>a</b>t <b>s</b>chool.
                                </label>
                            </div>

                            <div>
                                <label style={accessText}>
                                    Access your school plan from everywhere.
                                </label>
                            </div>

                            <div style={projectColorName}>
                                University Project (July 2019)
                            </div>

                            <div >
                                <Button style={projectActivity}>
                                    Software Development
                                </Button>
                                <Button style={projectActivity}>
                                    API
                                </Button>
                                <Button style={projectActivity}>
                                    UI Design
                                </Button>
                                <Button style={projectActivity}>
                                    Full-stack Web Development
                                </Button>
                            </div>

                            <div>
                                <a href={'/schoolplano'}>
                                    <Button style={schoolPlanoButton}>
                                        <b>V</b>iew more
                                    </Button>
                                </a>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>

            <img style={greenHeaderStyle} alt="photo" src={greenBottom}/>


            <Container style={gapStyle}>
                <Row xs="1" sm="2" md="2" lg="2">
                    <Col>

                        <div>
                            <label style={joinText}>
                                <b>D</b>rug <b>s</b>upport <b>f</b>or <b>p</b>hysicians.
                            </label>
                        </div>

                        <div>
                            <label>
                                Prevent dangerous side-effects.
                            </label>
                        </div>

                        <div style={projectName}>
                            University Project (January 2018)
                        </div>

                        <div>
                            <Button style={projectActivity}>
                                Software Development
                            </Button>
                            <Button style={projectActivity}>
                                Security
                            </Button>
                            <Button style={projectActivity}>
                                Full-stack Web Development
                            </Button>
                        </div>

                        <div>
                            <a href={'/medicationcare'}>
                                <Button style={kaxerButton}>
                                    <b>V</b>iew more
                                </Button>
                            </a>
                        </div>

                    </Col>
                    <Col>
                        <div style={brownSpotStyle}>
                            <a href={'/medicationcare'}>
                            <img style={phoneStyle} alt="photo" src={medicationCare}/>
                            </a>
                        </div>

                    </Col>
                </Row>
            </Container>

            <img style={greenHeaderStyle} alt="photo" src={redHeader}/>
            <div style={redRectStyle}>

                <Container>
                    <Row xs="1" sm="2" md="2" lg="2">
                        <Col>
                            <div style={spotRedStyle}>
                                <a href={'/facerec'}>
                                <img style={phoneStyle} alt="photo" src={facerec}/>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <label style={planText}>
                                    <b>O</b>bject <b>r</b>ecognition.
                                </label>
                            </div>

                            <div>
                                <label style={accessText}>
                                    Real-time object tracking and recognition.
                                </label>
                            </div>

                            <div style={projectColorName}>
                                University Project (January 2018)
                            </div>

                            <div >
                                <Button style={projectActivity}>
                                    Software Development
                                </Button>
                                <Button style={projectActivity}>
                                    Machine Learning
                                </Button>
                            </div>

                            <div>
                                <a href={'/facerec'}>
                                    <Button style={medicationCareButton}>
                                        <b>V</b>iew more
                                    </Button>
                                </a>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>
            <img style={greenHeaderStyle} alt="photo" src={redBottom}/>


            <Container style={gapStyle}>
                <Row xs="1" sm="2" md="2" lg="2">
                    <Col>

                        <div>
                            <label style={joinText}>
                                <b>H</b>uman <b>m</b>onitoring.
                            </label>
                        </div>

                        <div>
                            <label>
                                Secure real-time monitoring of vital signs.
                            </label>
                        </div>

                        <div style={projectName}>
                            University Project (June 2018)
                        </div>

                        <div >
                            <Button style={projectActivity}>
                                Software Development
                            </Button>
                            <Button style={projectActivity}>
                                Hardware Usage
                            </Button>
                            <Button style={projectActivity}>
                                Real-time Monitoring
                            </Button>
                            <Button style={projectActivity}>
                                Database
                            </Button>
                        </div>

                        <div>
                            <a href={'/pamosy'}>
                                <Button style={kaxerButton}>
                                    <b>V</b>iew more
                                </Button>
                            </a>
                        </div>

                    </Col>
                    <Col>
                        <div style={brownSpotStyle}>
                            <a href={'/pamosy'}>
                            <img style={phoneStyle} alt="photo" src={pamosy}/>
                            </a>
                        </div>

                    </Col>
                </Row>
            </Container>

            <img style={greenHeaderStyle} alt="photo" src={blueHeader}/>
            <div style={blueRectStyle}>

                <Container>
                    <Row xs="1" sm="2" md="2" lg="2">
                        <Col>
                            <div style={spotBlueStyle}>
                                <a href={'/intermedi'}>
                                <img style={phoneStyle} alt="photo" src={intermedi}/>
                                </a>
                            </div>
                        </Col>
                        <Col>

                            <div>
                                <label style={planText}>
                                    <b>D</b>igital <b>s</b>upport <b>f</b>or <b>n</b>urses.
                                </label>
                            </div>

                            <div>
                                <label style={accessText}>
                                    Workflow improvement of nurses.
                                </label>
                            </div>

                            <div style={projectColorName}>
                                Bachelor's Thesis (August 2017)
                            </div>

                            <div >
                                <Button style={projectActivity}>
                                    Mobile App Development
                                </Button>
                                <Button style={projectActivity}>
                                    Full-stack Web Development
                                </Button>
                                <Button style={projectActivity}>
                                    UI Design
                                </Button>
                            </div>

                            <div>
                                <a href={'/intermedi'}>
                                    <Button style={intermediButton}>
                                        <b>V</b>iew more
                                    </Button>
                                </a>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>
            <img style={greenHeaderStyle} alt="photo" src={blueBottom}/>

        </Router>
    )
};

const menuStyle = {
    marginLeft: '70%',
    marginRight: '5%',
};

const gapStyle = {
    marginTop: '2em',
};

const navbarStyle = {
    color: 'white',
    backgroundImage: `url(${header})`,
    width: '100vw',
    height: '100%',
};

const homeStyle = {
    marginLeft: '51vw',
    color: 'black',
};

const fontStyle = {
    color: 'black',
};

const nameStyle = {
    color: '#4c494b',
    fontSize: '30px',
    marginLeft: '1%',
};

const joinText = {
    fontSize: '35px',
    color: '#4c494b',
    marginTop: '10%',
};

const planText = {
    fontSize: '35px',
    color: 'white',
    marginTop: '20%',
};

const kaxerButton = {
    color: '#4c494b',
    backgroundColor: '#d6af84',
    borderRadius: '5px',
    border: '1px #4c494b',
    marginTop: '2%',
    outline: 'none',
};

const schoolPlanoButton = {
    color: 'white',
    backgroundColor: '#a1ab59',
    borderRadius: '5px',
    border: '1px #4c494b',
    marginTop: '2%',
    outline: 'none',
};

const medicationCareButton = {
    color: 'white',
    backgroundColor: '#b92123',
    borderRadius: '5px',
    border: '1px #4c494b',
    marginTop: '2%',
    outline: 'none',
};

const intermediButton = {
    color: 'white',
    backgroundColor: '#5c9cb6',
    borderRadius: '5px',
    border: '1px #4c494b',
    marginTop: '2%',
    outline: 'none',
};

const accessText = {
    color: 'white',
};

const projectStyle = {
    fontSize: '35px',
    textAlign: 'center',
    marginLeft: '45%',
    color: '#4c494b',
};

const projectName = {
    borderRadius: '20px',
    color: '#adadad',
};

const projectColorName = {
    borderRadius: '20px',
    color: '#e4e4e4',
};

const projectActivity = {
    margin: '10px 10px 0 0',
    padding: '4px 10px',
    borderRadius: '20px',
    border: '1px solid #dcdcdc',
    fontWeight: '600',
    marginLeft: '1px',
    backgroundColor: '#dcdcdc',
    color: '#4c494b',
};

const brownSpotStyle = {
    width: '420px',
    height: '100%',
    backgroundImage: `url(${spotBrown})`,
};

const spotGreenStyle = {
    width: '420px',
    height: '100%',
    backgroundImage: `url(${spotGreen})`,
};

const spotRedStyle = {
    width: '420px',
    height: '100%',
    backgroundImage: `url(${spotRed})`,
};

const spotBlueStyle = {
    width: '420px',
    height: '100%',
    backgroundImage: `url(${spotBlue})`,
};

const phoneStyle = {
    width: '58%',
    height: '100%',
    backgroundImage: `url(${phone})`,
    marginLeft: '22%',
};

const phoneLeftStyle = {
    width: '58%',
    height: '100%',
    backgroundImage: `url(${phone})`,
    marginLeft: '15%',
};

const rectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${rect})`,
    fontSize: '20px',
    color: '#f49366',
};

const greenHeaderStyle = {
    width: '100vw',
    height: '100%',
};

const greenRectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${greenRect})`,
};

const redRectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${redRect})`,
};

const blueRectStyle = {
    width: '100vw',
    height: '100%',
    backgroundImage: `url(${blueRect})`,
};

const generalStyle = {
    marginLeft: '0%',
    padding: '30px',
};

const formStyle = {
    background: 'white',
    color: 'white',
    textAlign: 'left',
    padding: '10px',
    width: '100%',
    display: 'flex',
    outline: 'none',
};

const inputStyle = {
    flex: '10',
    padding: '18px 16px',
    textAlign: 'center',
    marginLeft: '-10px',
    display: 'flex',
    borderRadius: '8px',
    border: '1px solid #ededed',
    outline: 'none',
};

const headerStyle = {
    color: '#4d4a4c',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '30px',
    display: 'flex',
    marginTop: '3.5em',
    fontStyle: 'HomepageBaukasten-Bold',
};

const subHeaderStyle = {
    color: '#a6a5a6',
    textAlign: 'left',
    display: 'flex',
};

const emailTextStyle = {
    color: '#9b999a',
    textAlign: 'left',
    marginTop: '3em',
};

const checkContainerStyle = {
    textAlign: 'left',
    display: 'flex',
};

const checkBoxTextStyle = {
    color: '#4e4d4e',
    marginLeft: '10px',
    verticalAlign: 'center',
    fontSize: '14px',
};

const HoverButton = styled.button`
    width: 30%;
    margin-top: 1.5em;
    padding: 15px;
    border-radius: 8px;
    border: solid #ececec 1px;
    color: black;
    background: white;
    font-weight: normal;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.01), 0 3px 6px 0 rgba(0,0,0,0.01);
    outline: none;
	:hover {
		color: white;
		background: #00CC66;
		cursor: pointer;
		border: solid #00CC66 2px;
		outline: none;
	}
`;

export default Home;
